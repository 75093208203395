import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/high-res.css'
import "../../assets/scss/main.scss";
import { useState } from 'react';

const CustomPhoneNumber = ({ formik, setPhoneValue, getPhoneCountry, setDisableBtn }) => {
    const [isError, setIsError] = useState(false);
    return (
        <span className="p-input-icon-right custom-phoneno-field">
            <PhoneInput
                inputProps={{
                    name: 'phone',
                    required: true,
                }}
                countryCodeEditable={false}
                autoFormat={true}
                onBlur={() => formik.setFieldTouched('phone', true)}
                enableSearch
                searchPlaceholder='Search'
                placeholder="Phone"
                country={'us'}
                value={formik.values.phone}
                onChange={(value, data) => {
                    const phoneValue = `+${value}`.replace(`+${data.dialCode}`, "");
                    if (getPhoneCountry && phoneValue.length === 0) {
                        setIsError(true);
                        setDisableBtn(true);
                    } else if (!getPhoneCountry && phoneValue.length === 0) {
                        formik.setFieldValue("phone", `+${value}`);
                        setPhoneValue(phoneValue);
                        formik.setFieldValue("callingCode", `+${data.dialCode}`);
                        setIsError(false);
                        setDisableBtn(false);
                    } else {
                        if (phoneValue.length >= 6) {
                            formik.setFieldValue("phone", `+${value}`);
                            setPhoneValue(phoneValue);
                            formik.setFieldValue("callingCode", `+${data.dialCode}`);
                            setIsError(false);
                            setDisableBtn(false);
                        } else {
                            setIsError(true);
                            setDisableBtn(true);
                        }
                    }
                }}
                style={{ 'width': '100%' }}
            >
            </PhoneInput>
            {(isError) ? <div className='error-message'>Invalid Phone number</div> : ''}
        </span>
    )
}

export default CustomPhoneNumber;