import React, { useState } from "react";
import * as PR from "../../prime-modules/index";
import { logoDark } from "../../assets/images/index";
import * as Yup from "yup";
import { useFormik } from "formik";
import { signup } from "../../services/api";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { handleNameChange, removeWhiteSpacesInPassword } from "../../utils";
import CustomPhoneNumber from "../components/CustomPhoneNumber";

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = React.useRef(null);
  const [phoneValue, setPhoneValue] = useState('');
  const [disableBtn, setDisableBtn] = useState(true);

  const formInitialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "+1",
    afid: "",
    websites: [],
    password: "",
    confirmPassword: "",
  };

  const validationSchema = () => {
    const passwordError = "Password should be between 8 and 20 characters with no space, atleast one character and one number"

    return Yup.object().shape({
      firstname: Yup.string()
        .trim()
        .required("First Name/Company Name is required")
        .min(3, "First Name/Company Name should be between 3 to 20 characters")
        .max(20, "First Name/Company Name should be between 3 to 20 characters"),
      lastname: Yup.string()
        .trim()
        .min(3, "Last Name should be between 3 to 20 characters")
        .max(20, "Last Name should be between 3 to 20 characters"),
      email: Yup.string()
        .trim()
        .email("Invalid email")
        .test('custom-email-validation', 'Invalid email', (value) => {
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
          return !value || emailRegex.test(value);
        })
        .required("Email is required"),
      phone: Yup.string().trim().required("Phone is required"),
      afid: Yup.string()
        .min(8, "Affiliate ID should be between 8 to 36 characters")
        .max(36, "Affiliate ID should be between 8 to 36 characters")
        .matches(
          /^[0-9a-zA-Z_-]{8,36}$/,
          "Affiliate ID should be between 8 to 36 characters and accepts 0-9, a-z, A-Z, -, _"
        ),
      password: Yup.string()
        .required("Password is required")
        .min(8, passwordError)
        .max(20, passwordError)
        .matches(/\d/, passwordError)
        .matches(/[A-Za-z]/, passwordError)
        .strict(true),
      confirmPassword: Yup.string()
        .required("Confirm Password is required")
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Passwords are not matching"
          ),
        }),
    });
  };

  const handleSubmit = (values) => {
    if (phoneValue === '') {
      setDisableBtn(true);
    } else {
      const phone = {
        localPhoneNumber: phoneValue,
        callingCode: values?.callingCode ? values?.callingCode : '',
      }
      const requestObj = { ...values }
      requestObj.phone = phone;
      delete requestObj.callingCode;
      const signupRequest = {
        firstname: values.firstname.trim(),
        lastname: values.lastname.trim(),
        email: values.email.trim(),
        phone: phone,
        afid: values.afid.trim(),
        websites: values.websites,
        password: values.password
      };
      const getLoginResponse = (response) => {
        if (response.result === "SUCCESS") {
          formik.resetForm();
          setPhoneValue('');
          toast.current.show({ severity: 'success', summary: 'Success', detail: 'Registered successfully.' });
          setTimeout(() => {
            setDisableBtn(false);
            navigate('/login');
          }, 2000);
        } else if (response.result === "FAILED" && response.error) {
          const error = response.error;
          toast.current.show({
            severity: error.severity,
            summary: "Error",
            detail: error.errorMsg ? error.errorMsg : error.summary,
          });
          setDisableBtn(false);
        }
      };
      signup(signupRequest, dispatch, getLoginResponse);
    }
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <section className="admin-login-section signup-section">
        <div className="grid grid-nogutter">
          <div className="col-12">
            <div className="login-wrapper">
              <div className="login-header flex align-items-center justify-content-between">
                <PR.Image
                  src={logoDark}
                  alt="awesim Logo"
                  className="logo-img"
                />
                <h1>Affiliates Sign Up</h1>
              </div>
              <form autoComplete="off" onSubmit={formik.handleSubmit} className="affiliate-forms">
                <div className="grid">
                  {/* First Name */}
                  <div className="col-12 md:col-12">
                    <span className="p-input-icon-left w-12">
                      <i className="pi pi-user" />
                      <PR.InputText
                        id="firstname"
                        name="firstname"
                        placeholder="First Name/Company Name"
                        className="w-12"
                        value={formik.values.firstname}
                        onChange={e => handleNameChange(e, formik, "firstname")}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                        keyfilter={"alphanum"}
                      />
                    </span>
                    {formik.errors.firstname && formik.touched.firstname ? (
                      <div className="error-message">
                        {formik.errors.firstname}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* Last Name */}
                  <div className="col-12 md:col-12">
                    <span className="p-input-icon-left w-12">
                      <i className="pi pi-user" />
                      <PR.InputText
                        id="lastname"
                        name="lastname"
                        placeholder="Last Name (Optional)"
                        className="w-12"
                        value={formik.values.lastname}
                        onChange={e => handleNameChange(e, formik, "lastname")}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                        keyfilter={"alphanum"}
                      />
                      {formik.errors.lastname && formik.touched.lastname ? (
                        <div className="error-message">
                          {formik.errors.lastname}
                        </div>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>

                  {/* Email */}
                  <div className="col-12 md:col-12">
                    <span className="p-input-icon-left w-12">
                      <i className="pi pi-envelope" />
                      <PR.InputText
                        id="email"
                        name="email"
                        placeholder="Email"
                        className="w-12"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                    </span>
                    {formik.errors.email && formik.touched.email ? (
                      <div className="error-message">{formik.errors.email}</div>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* Phone */}
                  <div className="col-12 md:col-12">
                    <span className="p-input-icon-left w-12">
                      <i className="pi pi-phone" />
                      <CustomPhoneNumber formik={formik} setPhoneValue={setPhoneValue} getPhoneCountry='us' setDisableBtn={setDisableBtn} />
                    </span>
                    {formik.errors.phone && formik.touched.phone ? (
                      <div className="error-message">{formik.errors.phone}</div>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* Affiliate Id */}
                  <div className="col-12 md:col-12 relative">
                    <span className="p-input-icon-left w-12">
                      <i className="pi pi-id-card" />
                      <PR.InputText
                        id="afid"
                        name="afid"
                        placeholder="Affiliate ID (Optional)"
                        className="w-12 afid"
                        value={formik.values.afid}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                    </span>

                    <span className="custom-target-icon pi pi-info-circle afid-span"
                      data-pr-tooltip="Affiliate ID should be between 8 to 36 characters and accepts 0-9, a-z, A-Z, -, _. If you don't have a preference leave it empty."
                      data-pr-position="top"
                      data-pr-my="center bottom-8">
                    </span>
                    <PR.Tooltip target=".custom-target-icon" />

                    {formik.errors.afid && formik.touched.afid ? (
                      <div className="error-message">{formik.errors.afid}</div>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* Websites */}
                  <div className="col-12 md:col-12">
                    <span className="p-input-icon-left w-12">
                      <i className="pi pi-link" />
                      <PR.Chips
                        id="websites"
                        name="websites"
                        value={formik.values.websites}
                        placeholder="Supported Websites/Advertising Location (Optional)"
                        separator=","
                        onChange={(e) => formik.setFieldValue("websites", e.value)}
                        addOnBlur={true}
                        allowDuplicate={false}
                        itemTemplate={(value) => {
                          if (value.length > 20) {
                            const truncatedContent = value.substring(0, 20) + '...';
                            return (
                              <div title={value}>
                                {truncatedContent}
                              </div>
                            );
                          } else {
                            return (
                              <div title={value}>
                                {value}
                              </div>
                            );
                          }
                        }}
                      />
                    </span>
                  </div>

                  {/* Password */}
                  <div className="col-12 md:col-12">
                    <span className="p-input-icon-left password-left w-12">
                      <PR.Password
                        feedback={false}
                        placeholder="Password"
                        id="password"
                        name="password"
                        className="w-12"
                        toggleMask
                        value={formik.values.password}
                        onChange={e => removeWhiteSpacesInPassword(e, formik, "password")}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                    </span>
                    {formik.errors.password && formik.touched.password ? (
                      <div className="error-message">
                        {formik.errors.password}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* Confirm Password */}
                  <div className="col-12 md:col-12">
                    <span className="p-input-icon-left password-left w-12">
                      <PR.Password
                        feedback={false}
                        placeholder="Confirm Password"
                        id="confirmPassword"
                        name="confirmPassword"
                        className="w-12"
                        toggleMask
                        value={formik.values.confirmPassword}
                        onChange={e => removeWhiteSpacesInPassword(e, formik, "confirmPassword")}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                    </span>
                    {formik.errors.confirmPassword &&
                      formik.touched.confirmPassword ? (
                      <div className="error-message">
                        {formik.errors.confirmPassword}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-12 mt-3 navigate">
                    <PR.Button
                      label="Sign Up"
                      type="submit"
                      className="login-btn w-12"
                      disabled={!formik.isValid || disableBtn}
                    />
                  </div>
                  <div className="col-12 text-center">
                    <p className="login-p">
                      Already have an account? <Link to="/login">Login</Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <PR.Toast ref={toast} position="top-right" />
    </>
  );
};

export default Signup;
