import React, { useState, useEffect, useRef } from "react";
import * as PR from "../../prime-modules/index";
import { logoLight } from "../../assets/images";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { affiliateAuthActions } from "../../store/affiliateAuth";

const AffiliateHeader = () => {
  const menu = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pathname, setPathName] = useState();
  const affiliateData = useSelector(state => state.affiliateAuth.affiliateSessionData);

  useEffect(() => {
    setPathName(window.location.pathname)
  },[])

  const items = [
    {
        label: "Logout",
        icon: "pi pi-power-off",
        command : () => {
          dispatch(affiliateAuthActions.onLogout())
        }
    }
  ];

  const menuItems = [
    {
      label: 'Bundle Sales',
      icon: 'pi pi-fw pi-list',
      command: () => {navigate('/sales')},
      className: (pathname === '/sales') ? 'active-menu' : ''
    }
  ];

  const end = <div className="flex align-items-center">
    <span className="user-icon"><i className="pi pi-user"></i></span>
    <PR.Button
        label={affiliateData.affiliateName}
        icon="pi pi-chevron-down"
        iconPos="right"
        onClick={(e) => menu.current.toggle(e)}
    />
    <PR.Menu model={items} popup ref={menu} />
    </div>;

  return (
    <>
      <Helmet>
        <title>awesim | Affiliates</title>
      </Helmet>
      <section className="admin-header-section">
        <div className="grid grid-nogutter">
          <div className="w-12 flex align-items-center justify-content-between">
            <PR.Image src={logoLight} alt="awesim Logo" />
            <h1>Affiliates Dashboard</h1>
          </div>
        </div>
      </section>
      <div className="w-12">
        <header className="grid grid-nogutter admin-header">
            <div className="w-12 flex align-items-center">
              <PR.Menubar model={menuItems} end={end} className="w-12" />
            </div>
        </header>
      </div>
    </>
  )
}

export default AffiliateHeader